import React from 'react'
import SbEditable from 'storyblok-react'

const Stat = props => {
  return (
    <SbEditable content={props.blok}>
      <div>{props.blok.statText}</div>
    </SbEditable>
  )
}

export default Stat
